import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/css/main.css'
import './assets/css/material-dashboard.css';
import './assets/css/nucleo-icons.css';
import './assets/css/nucleo-svg.css';
import { EnquiryPopup } from './EnquiryPopup'
import LOGO from "./images/logo.jpg"
import About from './assets/img/gallery/AboutPic.jpg'
import Abouttop from './assets/img/gallery/about.jpg'
import gallerypic1 from './assets/img/gallery/gallery9.jpg'
import gallerypic2 from './assets/img/gallery/gallery10.jpg'
import gallerypic11 from './assets/img/gallery/gallery11.jpg'
import gallerypic3 from './assets/img/gallery/gallerypic3.jpg'
import gallerypic4 from './assets/img/gallery/gallerypic4.jpg'
import gallerypic5 from './assets/img/gallery/gallerypic5.jpg'
import Principal from './assets/img/gallery/Principal.jpg'
import HeroBG from "./assets/img/hero-bg-2.jpg"
import location from "../components/images/location.png"
import facebook from "../components/images/facebook-logo.png"
import instagram from "../components/images/instagram.png"
import youtube from "../components/images/Youtube_logo.png"
import whatsapp from "../components/images/whatsapp.png"
import bell from "../components/images/bell.png"
import playstore from "../components/images/playstoredownload.svg"
import data from './assets/data.json'
import award1 from "../components/images/award1-Photoroom.png"
import eia from "../components/images/eia.png"
import award2 from "../components/images/award2-Photoroom.png"
import Details from './assets/img/Details.jpg';
import Details2 from './assets/img/Details2.jpg'
import Details3 from './assets/img/Details3.jpg'
import Details4 from '../components/images/sciencelab.jpg'
import school1 from './images/frontschool.jpg'
import background1 from "../components/images/background.png"
import school3 from './images/frontlogo.png'
import school4 from './assets/img/Details4.jpg'
import FAQ from './assets/img/faq.jpg'
import { ToastContainer, toast } from 'react-toastify';
import { left } from '@popperjs/core';

const testimonials = [
    {
        imgSrc: LOGO,
        name: 'MD Nasim',
        role: 'Ceo & Founder',
        quote: 'My two daughter is studying here, one of the best school in mango',
    },
    {
        imgSrc: LOGO,
        name: 'Saad Hussain',
        role: 'Ceo & Founder',
        quote: 'My son is studying there, very spacious with airy and ventilated and fully lighted classroom. I like this school',
    },
    {
        imgSrc: LOGO,
        name: 'Aizah  Aliza',
        role: 'Ceo & Founder',
        quote: `What we need today to give our child is a better education. The MBIS has a caring and responsive teachers for one's child. Especially love the hospitality of the principal sir Mr Tanweer Ahsan. Thanks a lot to him for having a big and strong vision in this education field.`,
    },
    {
        imgSrc: LOGO,
        name: 'Shahnawaz Alam',
        role: 'Ceo & Founder',
        quote: 'One of the Best CBSE school in mango jamshedpur. They focus on overall development of a students.',
    },
    {
        imgSrc: LOGO,
        name: 'SADAKAT ALI',
        role: 'Ceo & Founder',
        quote: 'It is a matter of great happiness for us that such a good school has opened in this area, and I have seen that there has been a lot of improvement in my cousin among the children, may Allah grant progress to the school.',
    },
    {
        imgSrc: LOGO,
        name: 'Sheikh Tanseem',
        role: 'Ceo & Founder',
        quote: 'Very well managed playschool in our area. Here, education is delivered with a sense of fun. There are numerous activities happening here that aim to promote the overall development of children.',
    },
    {
        imgSrc: LOGO,
        name: 'Ibrar Alam',
        role: 'Ceo & Founder',
        quote: 'This school is capable of handling both curricular and co-cirricular activities.The best thing is that children are given a lot of exposure to identify their talents.',
    },
    // Add more testimonials here
];
const Counter = ({ end, duration, isClicked }) => {
    const [count, setCount] = useState(end); // Default to the final number

    useEffect(() => {
        if (isClicked) {
            setCount(0); // Reset the counter to 0 when clicked
            let start = 0;
            const incrementTime = Math.abs(Math.floor(duration / end));

            const timer = setInterval(() => {
                start += 1;
                setCount((prevCount) => {
                    if (prevCount < end) {
                        return prevCount + 1;
                    } else {
                        clearInterval(timer);
                        return end;
                    }
                });
            }, incrementTime);

            return () => clearInterval(timer); // Cleanup interval on unmount or click end
        }
    }, [isClicked, end, duration]); // Rerun effect on click state change

    return <span>{count}</span>;
};



function Home() {
    const [SchoolName, setSiteName] = useState('');
    const [StartContentHeader, setStartContentHeader] = useState('');
    const [StartContent, setStartContent] = useState('');
    const [affiliation, setaffiliation] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);
    const [clickedIndex, setClickedIndex] = useState(-1);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [showPopup, setShowPopup] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
        //document.body.classNameList.toggle('mobile-nav-active', !isMenuOpen); // Control body overflow
        document.body.classList.toggle('mobile-nav-active', !isMenuOpen);

    };
    // console.log(isMenuOpen);


    const handleOnClick = (index) => {
        setClickedIndex(index);
    };
    const stats = [
        { end: 1046, duration: 5, label: 'Satisfied Parents' },
        { end: 117, duration: 5, label: 'Extracurricular Activities' },
        { end: 15450, duration: 5, label: 'Hours of Tutoring' },
        { end: 35, duration: 5, label: 'Dedicated Faculty Members' },
    ];

    useEffect(() => {
        //PureCounter();
        setSiteName(data.SchoolName);
        setStartContentHeader(data.StartContentHeader);
        setStartContent(data.StartContent);
        setaffiliation(data.affiliation);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) =>
                prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
            );
        }, 3000); // Change slide every 3 seconds

        return () => clearInterval(interval); // Cleanup on unmount
    }, []);
    // State to manage the active FAQ item
    const [activeFaqIndex, setActiveFaqIndex] = useState(null);

    // Toggle FAQ item
    const toggleFaq = (index) => {
        // If the same item is clicked, close it, otherwise open the clicked item
        setActiveFaqIndex(activeFaqIndex === index ? null : index);
    };

    const [openIndex, setOpenIndex] = useState(null); // Track which item is open
    const contentRefs = useRef([]);
    useEffect(() => {
        if (openIndex !== null) {
            const content = contentRefs.current[openIndex];
            content.style.maxHeight = content.scrollHeight + 'px'; // Smooth expand
        }

        // Close the previous section smoothly
        return () => {
            if (openIndex !== null) {
                const content = contentRefs.current[openIndex];
                content.style.maxHeight = '0';
            }
        };
    }, [openIndex]);

    const features = [
        { title: "ISO Certified", icon: "bi-eye", color: "#ffbb2c", description: "ISO 9001:2015  recognizes the fundamental contribution that educational institutions and especially school can give to standardization in terms of teaching what can be achieved through standards. Participating in the developments of standards providing the invaluable contribution of academics work" },
        { title: "Best Curriculum", icon: "bi-infinity", color: "#5578ff", description: `Our education experts have designed a range of board,balanced,inovative and comprehensive curriculum that meet the educational needs of every child on national and international standard.` },
        { title: "Islamic Studies", icon: "bi-mortarboard", color: "#e80368", description: `To acquire knowledge has been made compulsory by divine commmandment. the purpose of education in islam is to associate and balance physical and spiritual development of humankind. Moreover,islam deals with the universal education and education for practical life and moral values.` },
        {
            title: "Communication Skills", icon: "bi-nut", color: "#e361ff", description: `We develop your child's Communication Skills.
            They can get rid of their Shyness. No Stage Fright, Debating, Speeches, Presentation, Poetry, storytelling, Public Speaking, Spoken English.
            English Reading & Writing.`},
        { title: "Computer Skills   ", icon: "bi-shuffle", color: "#47aeff", description: "Computers help the students to learn about the world and know what is happening.It helps to provide access to such a huge variety of information and have supplied infinite resources for learning and made education flexible and easy to access." },
        { title: "No Tuition Required", icon: "bi-star", color: "#ffa76e", description: "No extra tuition classes required for our  students" },
        {
            title: "Sports & Game Activity", icon: "bi-x-diamond", color: "#11dbcf", description: `Engaging sports and activities for physical development. 
                      Sports are important in schools as they teach various skills to students, 
                      including leadership, patience, teamwork, and social skills.` },
        {
            title: "Physical & Mental Strength", icon: "bi-camera-video", color: "#4233ff", description: `Promoting both physical and mental health is vital from individual, social, 
                      and economic perspectives. Schools play a crucial role in effectively 
                      promoting the well-being of children and youth.` },
        {
            title: "Spiritual Awakening", icon: "bi-command", color: "#b2904f", description: `You may feel a higher sense of purpose, peace, hope, and meaning. You may experience better confidence, self-esteem, and self-control.
            It can help you make sense of your experiences in life. When unwell, it can help you feel inner strength and result in faster recovery.` },
        {
            title: "Art & Crafts", icon: "bi-dribbble", color: "#b20969", description: `Art & Craft allows kids to develop their creativity, which is important throughout their lives. By doing something creative, you allow for self-expression and this lets kids express themselves. It also fosters mental growth by providing opportunities for trying out
        new ideas, new ways of thinking and problem solving.`},
        {
            title: "Creative Learning Environment", icon: "bi-activity", color: "#ff5828", description: `Creativity Involves critical thinking, planning and focusing your attention- Children learn through this process as different parts of brain jump into action.
        They might discover something they never knew before, learn how to solve a problem, or figure out a new way to look at or do something` },
        { title: "Focus on Wellness", icon: "bi-brightness-high", color: "#29cc61", description: `It help students make their studies easier to understand and make their learning experience comfortable and enjoyable. Good manner creates an effective interaction with the People as well as make a good Impression on a public platform. It helps us to be positive throughout the day.` }
    ];

    const handleToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index); // Toggle open/close
    };


    const handleEnquiryClick = () => {
        // Scroll the page to the top
        window.scrollTo({ top: 0, behavior: 'smooth' });
        // Open the popup
        setShowPopup(true);
    };

    const [menuHeight, setMenuHeight] = useState('80%');

    useEffect(() => {
        // Function to update menu height based on screen width
        const updateMenuHeight = () => {
            if (window.innerWidth <= 768) { // iPad or similar screen width
                setMenuHeight('80%'); // Set height to 50% for iPad screen size
            } else {
                setMenuHeight('50%'); // Set height to 80% for larger screens
            }
        };

        // Initial check
        updateMenuHeight();

        // Add resize event listener
        window.addEventListener('resize', updateMenuHeight);

        // Cleanup on component unmount
        return () => window.removeEventListener('resize', updateMenuHeight);
    }, []);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 992); // Default based on screen size

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 992);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);




    return (
        <>
            <div className="index-page"
            >
                <header id="header" className="header d-flex align-items-center fixed-top py-2">
                    <div className="container-fluid container-xl position-relative d-flex align-items-center justify-content-between" >

                        <a href="" className="logo d-flex align-items-center ">
                            <img src={LOGO} className='rounded-pill mr-4' alt="" style={{ height: '240px !important', width: 'auto', padding: '0px' }} />
                            {/* <span style={{ fontSize: '1.2em', fontWeight: 'bold', margin: '0',transform: 'rotate(-90deg)',display: 'inline-block' }}>THE</span> */}
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    fontSize: '0.8em',
                                    fontWeight: 'bold',
                                    lineHeight: '0.8em', // Reduce spacing between lines
                                    margin: '0',
                                    padding: '0',
                                    marginBottom: '8px',
                                    marginLeft: '10px',
                                    textAlign: 'center' // Center align the letters
                                }}
                            >
                                <span style={{ transform: 'rotate(-90deg)', display: 'inline-block', margin: '0', padding: '0', color: 'white' }}>E</span>
                                <span style={{ transform: 'rotate(-90deg)', display: 'inline-block', margin: '0', padding: '0', color: 'white' }}>H</span>
                                <span style={{ transform: 'rotate(-90deg)', display: 'inline-block', margin: '0', padding: '0', color: 'white' }}>T</span>
                            </div>
                            <h2 className="sitename" style={{ color: "white", display: 'flex', alignItems: 'center' }}>
                                M
                                <img
                                    src={bell}
                                    alt="O"
                                    style={{ height: '0.8em', width: '1em', margin: '0' }}
                                />
                                RNING BELLS
                            </h2>
                        </a>

                        <nav id="navmenu" className={`navmenu ${isMenuOpen ? 'open ' : ''} `} style={{
                            height: isMenuOpen ? menuHeight : '',
                            //top: '80px',                      
                            backgroundColor: 'transparent',
                        }}>
                            <ul  >
                                <li ><a href="#hero" className="active" onClick={toggleMenu}>Home</a></li>
                                <li ><a href="#about" onClick={toggleMenu}>About</a></li>
                                <li ><a href="#features" onClick={toggleMenu}>Features</a></li>
                                <li ><a href="#gallery" onClick={toggleMenu}>Gallery</a></li>
                                <li ><a href="#team" onClick={toggleMenu}>Team</a></li>

                                <li ><a href="#footer" onClick={toggleMenu}>Contact</a></li>

                                {/* <li ><Link to="/loginflutter" onClick={toggleMenu}>Login</Link> </li> */}
                            </ul>
                            <i className={`mobile-nav-toggle d-xl-none bi ${isMenuOpen ? 'bi-x' : 'bi-list'}`} onClick={toggleMenu}></i>

                        </nav>

                    </div>


                </header>

                {/* Social Media Icon Bar */}
                <div
                    className="d-flex flex-column align-items-center position-fixed m-0 p-0"
                    style={{
                        top: isMobile ? '150px' : '130px',
                        right: '0',
                        //transform: 'translateY(-50%)',
                        zIndex: 999,
                        padding: '0px',
                        opacity: 0.9,
                    }}
                >
                    <a href="https://www.facebook.com/morningbellsschooljsr/" target="_blank" rel="noopener noreferrer" className="btn m-0 p-0 mb-2">
                        <img src={facebook} className="m-0" style={{ height: isMobile ? '30px' : '50px', width: 'auto', padding: '0px' }}></img>
                    </a>
                    <a href="https://www.instagram.com/themorningbellsinternational/" target="_blank" rel="noopener noreferrer" className="btn  p-0 m-0 mb-2">
                        <img src={instagram} className="m-0" style={{ height: isMobile ? '30px' : '50px', width: 'auto', padding: '0px' }}></img>
                    </a>
                    <a href="https://www.youtube.com/@morningbellsinternationals9455" target="_blank" rel="noopener noreferrer" className="btn p-0 m-0 mb-2">
                        <img src={youtube} className="m-0" style={{ height: isMobile ? '20px' : '40px', width: isMobile ? '30px' : '50px', padding: '0px' }}></img>
                    </a>
                    <a href="https://wa.me/919771210427" target="_blank" rel="noopener noreferrer" className="btn p-0 m-0">
                        <img src={whatsapp} className="m-0" style={{ height: isMobile ? '30px' : '50px', width: 'auto', padding: '0px' }}></img>
                    </a>
                </div>
                <div className="main">
                    {/* Marquee Section */}
                    <div
                        className="marquee-container"
                        style={{
                            backgroundColor: 'rgba(246, 215, 49, 0.9)', // Semi-transparent dark background
                            backdropFilter: 'blur(5px)',
                            width: '100%',
                            //backgroundColor: 'transparent',
                            position: 'fixed', // Fixes the position
                            top: isMobile ? '57px' : '70px', // Adjust based on header height
                            zIndex: 99,
                            paddingTop: isMobile ? '2%' : '0.5%',
                            //marginBottom: '10px',

                        }}
                    >
                        <marquee behavior={isMobile ? "scroll" : "alternate"} direction="left" style={{ color: 'black', Text: 'bold', fontSize: isMobile ? '12px' : '16px' }}>
                            Hurry up ! Free Admission is going on - (Limited seats only)
                        </marquee>
                    </div>
                    {/* <!-- Hero Section --> */}
                    <section id="hero" className="hero section dark-background">
                        <img src={HeroBG} alt="" className="hero-bg" />

                        <div className="container">
                            <div className="row gy-4 justify-content-between" >
                                {/* <div className="col-lg-3 order-lg-last hero-img" data-aos="zoom-out" data-aos-delay="100" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <img src={LOGO} className="img-fluid animated" alt="" style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
                                    {/* <img src={FrontSchool} className="img-fluid animated" alt="" /> 


                                </div> */}
                                <div className="col-lg-3 order-lg-last hero-img d-flex flex-column align-items-center py-2"
                                    data-aos="zoom-out"
                                    data-aos-delay="100">

                                    {/* Logo */}
                                    <img
                                        src={LOGO}
                                        className="img-fluid animated m-4 rounded-pill"
                                        alt="Logo"
                                        style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                                    />

                                    {/* Enquiry Button */}
                                    <button
                                        className="btn btn-warning mt-3 animate-bounce animated "
                                        onClick={handleEnquiryClick}
                                        style={{ width: '150px', fontWeight: 'bold', backgroundColor: '#FF7777', color: 'white', }}
                                    >
                                        Enquiry Now
                                    </button>
                                </div>


                                <div className="col-lg-7 d-flex flex-column justify-content-center" data-aos="fade-in" >
                                    {/* <h6 style={{ color: 'white' }}>{StartContentHeader}, </h6> */}
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                        <img src={school3} className="m-0 " alt="Logo" style={{ maxWidth: '100%', height: '120px', objectFit: 'contain' }} />
                                    </div>

                                    {/* <a href="" className="logo d-flex align-items-center ">



                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                fontSize: isMobile ? '0.6em' : '0.8em',
                                                fontWeight: 'bold',
                                                lineHeight: '0.7em', // Reduce spacing between lines
                                                margin: '0',
                                                padding: '0',
                                                marginBottom: isMobile ? '38px' : '8px',
                                                textAlign: 'right' // Center align the letters
                                            }}
                                        >
                                            <span style={{ transform: 'rotate(-90deg)', display: 'inline-block', margin: '0', padding: '0', color: 'white' }}>E</span>
                                            <span style={{ transform: 'rotate(-90deg)', display: 'inline-block', margin: '0', padding: '0', color: 'white' }}>H</span>
                                            <span style={{ transform: 'rotate(-90deg)', display: 'inline-block', margin: '0', padding: '0', color: 'white' }}>T</span>
                                        </div>
                                        
                                        {
                                            isMobile ? (
                                                <>



                                                    <h3 className="sitename" style={{ color: "white", display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                                                        <span style={{ display: 'flex', alignItems: 'left' }}>
                                                            M
                                                            <img
                                                                src={bell}
                                                                alt="O"
                                                                style={{ height: '0.9em', width: '1em', margin: '0 0em', marginTop: '3px' }}
                                                            />
                                                            RNING BELLS
                                                        </span>
                                                        <span style={{ color: '#f6d731' }}>INTERNATIONAL SCHOOL</span>
                                                    </h3>
                                                </>
                                            ) : (
                                                <h2 className="sitename" style={{ color: "white", display: 'flex', alignItems: 'left' }}>
                                                    M
                                                    <img
                                                        src={bell}
                                                        alt="O"
                                                        style={{ height: '0.8em', width: '1em', margin: '0' ,marginTop:'7px'}}
                                                    />
                                                    RNING BELLS <span style={{ color: '#f6d731' }}> &nbsp; INTERNATIONAL SCHOOL</span>
                                                </h2>
                                            )

                                        }

                                    </a> */}
                                    <p style={{ fontSize: '18px', color: "#f6d731", margin: '0px' }}>An ISO 9001:2015 Certified English Medium School</p>
                                    <p style={{ fontSize: '18px' }}>{StartContent}</p>
                                    <div className="d-flex">

                                        {/* <a href="https://www.youtube.com/watch?v=Y7f98aduVJ8" className="glightbox btn-watch-video d-flex align-items-center"><i className="bi bi-play-circle"></i><span>Watch Video</span></a> */}

                                        <button
                                            className="btn btn-warning"
                                            //onClick={handleEnquiryClick}
                                            style={{ width: 'auto', fontWeight: 'bold' }}
                                        >
                                            Download Our App
                                        </button>
                                    </div>
                                </div>

                            </div>
                            {/* Enquiry Popup */}
                            <EnquiryPopup show={showPopup} onClose={() => setShowPopup(false)} />
                        </div>

                        <svg className="hero-waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none">
                            <defs>
                                <path id="wave-path" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
                            </defs>
                            <g className="wave1">
                                <use xlinkHref="#wave-path" x="50" y="3"></use>
                            </g>
                            <g className="wave2">
                                <use xlinkHref="#wave-path" x="50" y="0"></use>
                            </g>
                            <g className="wave3">
                                <use xlinkHref="#wave-path" x="50" y="9"></use>
                            </g>
                        </svg>

                    </section>
                    {/* <!-- /Hero Section -->

                    <!-- About Section --> */}
                    <section id="about" className="about section">

                        <div className="container" data-aos="fade-up" data-aos-delay="100">
                            <div className="row align-items-xl-center gy-5">

                                <div className="col-xl-5 content">
                                    <h3>About Us</h3>
                                    <h2>The Morning Bells International School : Best kids School | Play School | Pre School | Best School in Mango Jamshedpur</h2>
                                    <p>The Morning Bells International School is a leading institution in Mango, Jamshedpur, offering top-tier education from Play School to School run and managed by
                                    Wake Up Educational and Welfare Trust. With a nurturing environment, we focus on early childhood development, providing the best foundation for young learners to grow academically and socially.</p>
                                    <h3>Awards & Recognition</h3>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            position: 'relative',
                                            marginBottom: '10px'
                                        }}
                                    >
                                        {/* Left Image */}
                                        <div  style={{ textAlign: 'center' }}>
                                            <img
                                                src={award1}
                                                alt="Left Corner"
                                                style={{ maxWidth: '100px', height: '100px' }}
                                            />
                                            <p  style={{ marginTop: '10px',color: '#1E176C'  }}>Teaching Excellence Award By EIA</p>
                                        </div>

                                        {/* Center Text */}
                                        <p style={{ flexGrow: 1, textAlign: 'center', margin: '0 20px', }}>
                                            <img
                                                    src={eia}
                                                    alt="Right Corner"
                                                    style={{ maxWidth: '120px', height: '100px' }}
                                                />
                                        </p>

                                        {/* Right Image */}
                                        <div style={{ textAlign: 'center' }}>
                                            <img
                                                src={award2}
                                                alt="Right Corner"
                                                style={{ maxWidth: '100px', height: '100px' }}
                                            />
                                            <p style={{ marginTop: '10px',color: '#1E176C' }}>Best Pre-School Of the Year Award By IEA</p>
                                        </div>
                                    </div>
                                    <img src={About} alt="" style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
                                </div>

                                <div className="col-xl-7">
                                    <div className="row gy-4 icon-boxes">

                                        <div className="col-md-6" data-aos="fade-up" data-aos-delay="200">
                                            <div className="icon-box">
                                                <i className="bi bi-buildings"></i>
                                                <h3>Vision & Mission</h3>
                                                <p>At {SchoolName}, we nurture future leaders through holistic education, fostering academic excellence, integrity, and global awareness. Our vision is to empower students as lifelong learners, critical thinkers, and responsible citizens.</p>
                                            </div>
                                        </div>
                                        {/* */}

                                        <div className="col-md-6" data-aos="fade-up" data-aos-delay="300">
                                            <div className="icon-box">
                                                <i className="bi bi-clipboard-pulse"></i>
                                                <h3>CBSE Curriculum</h3>
                                                <p>
                                                    Our CBSE-affiliated curriculum meets high educational standards, offering a balanced, student-centric approach that integrates academics with extracurriculars for holistic development.</p>
                                            </div>
                                        </div>

                                        <div className="col-md-6" data-aos="fade-up" data-aos-delay="400">
                                            <div className="icon-box">
                                                <i className="bi bi-command"></i>
                                                <h3>State-of-the-Art Infrastructure</h3>
                                                <p>Our campus features modern facilities like smart classrooms, labs, a library, and sports amenities, providing a safe, stimulating environment for academic and co-curricular growth.</p>
                                            </div>
                                        </div>

                                        <div className="col-md-6" data-aos="fade-up" data-aos-delay="500">
                                            <div className="icon-box">
                                                <i className="bi bi-graph-up-arrow"></i>
                                                <h3>Life Beyond Academics</h3>
                                                <p>At {SchoolName},  we foster holistic development through diverse extracurriculars, including sports, art, and community service, promoting skills, teamwork, and leadership beyond the classroom.</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>

                    </section>
                    {/* <!-- /About Section -->

                    <!-- Features Section --> */}
                    <section id="features" className="features section">
                        <div className="container ">

                            <div className="col-xl-5 content section-title">

                                <h2 style={{ color: '#1E176C' }}>WHY US ? </h2>
                                <div><span>WHY MORNING </span> <span className="description-title">BELLS ?</span></div>

                            </div>

                            <div className="row gy-4">

                                {features.map((feature, index) => (
                                    <div className="col-lg-3 col-md-4 " data-aos="fade-up" data-aos-delay={100 * (index + 1)} key={index}>
                                        <div
                                            className="features-item"
                                            onClick={() => handleToggle(index)}
                                            style={{ cursor: 'pointer', borderRadius: '4px', }}
                                        >
                                            <i className={`bi ${feature.icon}`} style={{ color: feature.color }}></i>
                                            <h3 className="m-1">
                                                {feature.title}

                                            </h3>
                                            <i className={`bi ${openIndex === index ? 'bi-chevron-up' : 'bi-chevron-down'} `} style={{ fontSize: '16px', position: 'absolute', right: '8px' }}></i>



                                        </div>
                                        <div className="content p-2" style={{ marginTop: '4px' }}>
                                            <p ref={(el) => (contentRefs.current[index] = el)}
                                                className="content w-full"
                                                style={{
                                                    maxHeight: '0', // Start collapsed
                                                    overflow: 'hidden',
                                                    transition: 'max-height 0.4s ease-in-out',
                                                }}>{feature.description}</p>
                                        </div>

                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                    {/* <!-- /Features Section -->

                    <!-- Stats Section --> */}
                    <section id="stats" className="stats section light-background">
                        <div className="container" data-aos="fade-up" data-aos-delay="100">
                            <div className="row gy-4">
                                {stats.map((stat, index) => (
                                    <div
                                        key={index}
                                        className="col-lg-3 col-md-6 d-flex flex-column align-items-center"
                                        onClick={() => handleOnClick(index)} // Handle click event
                                    >
                                        <i className={`bi ${index === 0 ? 'bi-emoji-smile' : index === 1 ? 'bi-trophy' : index === 2 ? 'bi-headset' : 'bi-people'}`}></i>
                                        <div className="stats-item">
                                            <Counter
                                                end={stat.end}
                                                duration={stat.duration}
                                                isClicked={clickedIndex === index} // Pass clicked state to the Counter
                                            />
                                            <p>{stat.label}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>
                    {/* <!-- /Stats Section -->

                    <!-- Details Section --> */}
                    <section id="details" className="details section">

                        {/*  */}
                        <div className="container section-title" data-aos="fade-up">
                            <h2>Details</h2>
                            <div><span>Check Our</span> <span className="description-title">Details</span></div>
                        </div>
                        {/*  */}

                        <div className="container">

                            <div className="row gy-4 align-items-center features-item">
                                <div className="col-md-5 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="100">
                                    <img src={Details} className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-7" data-aos="fade-up" data-aos-delay="100">
                                    <h3>Playground and Outdoor Facilities</h3>
                                    <p className="fst-italic">
                                        At our school, we believe that a healthy body fosters a healthy mind. Our expansive playground and outdoor sports facilities provide students with the perfect environment for physical activity, teamwork, and fun. From the well-maintained soccer and basketball courts to the creative play structures and open green spaces, there’s something for every child to enjoy. Whether it’s during recess or after-school activities, our outdoor areas offer students the chance to build friendships, develop athletic skills, and enjoy the great outdoors in a safe and nurturing environment.
                                    </p>
                                    <ul>
                                        <li><i className="bi bi-check"></i><span> Promotes physical health and active play.</span></li>
                                        <li><i className="bi bi-check"></i> <span>Encourages teamwork and social skills.</span></li>
                                        <li><i className="bi bi-check"></i> <span>Provides a safe and fun environment for all ages.</span></li>
                                    </ul>
                                </div>
                            </div>
                            {/*  */}

                            <div className="row gy-4 align-items-center features-item">
                                <div className="col-md-5 order-1 order-md-2 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
                                    <img src={Details2} className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-7 order-2 order-md-1" data-aos="fade-up" data-aos-delay="200">
                                    <h3>Computer Lab and Technology Resources</h3>
                                    <p className="fst-italic">
                                        In today’s digital age, we are committed to equipping our students with the skills they need to thrive. Our state-of-the-art computer lab is a hub for technological learning, featuring the latest computers, interactive smartboards, and a variety of educational software. Here, students learn essential digital literacy skills, explore coding and robotics, and engage in creative multimedia projects. Guided by experienced teachers, they are empowered to become confident, competent users of technology, ready for the challenges of the future.
                                    </p>
                                    <ul>
                                        <li><i className="bi bi-check"></i><span> Prepares students for a tech-driven world.</span></li>
                                        <li><i className="bi bi-check"></i> <span>Encourages creativity and problem-solving.</span></li>
                                        <li><i className="bi bi-check"></i> <span>Fosters confidence in digital skills.</span></li>
                                    </ul>
                                </div>
                            </div>
                            {/*  */}

                            <div className="row gy-4 align-items-center features-item">
                                <div className="col-md-5 d-flex align-items-center" data-aos="zoom-out">
                                    <img src={Details3} className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-7" data-aos="fade-up">
                                    <h3>Library and Study Areas</h3>
                                    <p>Our library is more than just a place to find books – it’s a dynamic learning environment where curiosity is encouraged, and a love for reading is nurtured. With a rich collection of literature, reference materials, and digital resources, students have access to everything they need for both academic study and personal enjoyment. Cozy reading corners, collaborative study spaces, and dedicated research areas make our library a favorite spot for many. Here, every student is invited to explore new worlds, discover new passions, and deepen their knowledge</p>
                                    <ul>
                                        <li><i className="bi bi-check"></i> <span>Inspires a lifelong love of reading.</span></li>
                                        <li><i className="bi bi-check"></i><span> Supports academic growth and creativity.</span></li>
                                        <li><i className="bi bi-check"></i> <span>Provides a welcoming space for all learners.</span>.</li>
                                    </ul>
                                </div>
                            </div>
                            {/*  */}

                            <div className="row gy-4 align-items-center features-item">
                                <div className="col-md-5 order-1 order-md-2 d-flex align-items-center" data-aos="zoom-out">
                                    <img src={Details4} className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-7 order-2 order-md-1" data-aos="fade-up">
                                    <h3>Science Laboratories</h3>
                                    <p className="fst-italic">
                                        Hands-on learning is at the heart of our science program. Our well-equipped science laboratories provide a safe and stimulating environment where students can explore the wonders of biology, chemistry, and physics. From conducting experiments to developing innovative projects, our students are encouraged to question, investigate, and discover. With access to modern equipment and resources, and under the guidance of passionate teachers, they develop a deep understanding of scientific principles and a genuine enthusiasm for STEM.
                                    </p>
                                    <ul>
                                        <li><i className="bi bi-check"></i><span> Sparks curiosity and critical thinking.</span></li>
                                        <li><i className="bi bi-check"></i> <span>Encourages hands-on exploration and discovery.</span></li>
                                        <li><i className="bi bi-check"></i> <span>Builds a strong foundation in STEM education.</span></li>
                                    </ul>
                                </div>
                            </div>

                        </div>

                    </section>
                    <section id="team" className="team section">


                        <div className="container section-title" data-aos="fade-up">
                            <h2>Director</h2>
                            {/* <div><span>Check Our</span> <span className="description-title">Team</span></div> */}
                        </div>

                        <div className="container">

                            <div className="row gy-5">

                                <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
                                    <div className="member">
                                        <div className="pic"><img src={Principal} className="img-fluid" alt="" /></div>
                                        <div className="member-info">
                                            <h4>TANWEER AHSAN</h4>
                                            <span>Director</span>
                                            <div className="social">
                                                {/* <a href=""><i className="bi bi-twitter-x"></i></a>
                                                <a href=""><i className="bi bi-facebook"></i></a>
                                                <a href=""><i className="bi bi-instagram"></i></a>
                                                <a href=""><i className="bi bi-linkedin"></i></a> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                {/* Second Column - Paragraph */}
                                <div className="col-lg-8 col-md-6  " data-aos="fade-up" data-aos-delay="200">
                                    <div className="member-info shadow-sm rounded p-4 m-4" >
                                        <h4 className='p-2'>About Our Director</h4>

                                        <p className='p-2'>
                                            <i className="fa fa-quote-left "></i>&nbsp;
                                            As an educator with many years of experience, I believe that learning reaches far beyond the classroom walls. Education begins at home, where a child first absorbs values, manners, and perspectives from their family. By the time they join us, a foundation has already been laid, and our role as educators is to build upon it.
                                            <br />

                                            Teaching academics is essential, but true education instills respect for others, civic responsibility, and a love for lifelong learning. At our school, we strive to nurture these qualities, understanding that children learn best from what they observe and experience in the world around them. We are committed to guiding each child’s growth in a way that equips them to thrive both academically and as compassionate, engaged citizens.&nbsp;
                                            <i className="fa fa-quote-right" ></i>
                                        </p>

                                    </div>
                                </div>
                            </div>

                        </div>

                    </section>


                    <section id="gallery" className="gallery section">


                        <div className="container section-title" data-aos="fade-up">
                            <h2>Gallery</h2>
                            <div><span>Check Our</span> <span className="description-title">Gallery</span></div>
                        </div>

                        <div className="container" data-aos="fade-up" data-aos-delay="100">

                            <div className="row g-2">

                                {[
                                    school1, school4, gallerypic1, gallerypic2, gallerypic11,
                                    gallerypic3, gallerypic4, gallerypic5
                                ].map((imgSrc, index) => (
                                    <div key={index} className="col-lg-3 col-md-4">
                                        <div
                                            className="gallery-item overflow-hidden rounded"
                                            style={{ height: '200px' }}
                                        >
                                            <a
                                                href={`./assets/img/gallery/gallery-${index + 1}.jpg`}
                                                className="glightbox"
                                                data-gallery="images-gallery"
                                            >
                                                <img
                                                    src={imgSrc}
                                                    alt={`Gallery ${index + 1}`}
                                                    className="img-fluid w-100 h-100"
                                                    style={{ objectFit: 'cover' }}
                                                />
                                            </a>
                                        </div>
                                    </div>
                                ))}

                            </div>

                        </div>

                    </section>
                    <div className="container section-title" data-aos="fade-up">
                        <h2>Review</h2>
                        <div><span>Parents</span> <span className="description-title">Speak</span></div>
                    </div>

                    {/* <!-- Testimonials Section --> */}
                    <section id="testimonials" className="testimonials section light-background p-2" style={{ height: '500px' }}>


                        <img src={background1} className="testimonials-bg" alt="" />

                        <div className="container p-2" data-aos="fade-up" style={{ height: '430px' }}>

                            <div className="row p-2 " style={{ height: '410px' }}>
                                {[currentIndex, (currentIndex + 1) % testimonials.length].map((index) => (
                                    <div key={index} className={`col-12 col-md-6 ${index !== currentIndex ? 'd-md-block d-none' : ''}`}>
                                        <div className="testimonial-item  p-2  rounded shadow" style={{ height: '410px' }}>
                                            {/* Uncomment if you want to display user images */}
                                            <div
                                                className="testimonial-initial"
                                                style={{
                                                    width: '80px',
                                                    height: '80px',
                                                    borderRadius: '50%',
                                                    backgroundColor: '#007bff', // Replace with desired background color
                                                    color: '#fff',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    fontSize: '32px',
                                                    //fontWeight: 'bold',
                                                    margin: '0 auto 20px', // Center and add bottom margin
                                                }}
                                            >
                                                {testimonials[index].name.split(' ').map((namePart) => namePart.charAt(0).toUpperCase()).join('')}
                                            </div>
                                            <h3>{testimonials[index].name}</h3>
                                            {/* <h4>{testimonials[index].role}</h4> */}
                                            <div className="stars mb-2" >
                                                {[...Array(5)].map((_, i) => (
                                                    <i key={i} className="bi bi-star-fill"></i>
                                                ))}
                                            </div>
                                            <p >
                                                <i className="bi bi-quote quote-icon-left"></i>
                                                {testimonials[index].quote}
                                                <i className="bi bi-quote quote-icon-right"></i>
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                    </section>




                    <section id="faq" className="faq section light-background">

                        <div className="container-fluid">

                            <div className="row gy-4">

                                <div className="col-lg-7 d-flex flex-column justify-content-center order-2 order-lg-1">

                                    <div className="content px-xl-5" data-aos="fade-up" data-aos-delay="100">
                                        <h3><span>Frequently Asked </span><strong>Questions</strong></h3>
                                        <p>
                                            Welcome to the FAQ section! Below, you'll find answers to some of the most common questions regarding our school, its facilities, and admissions process. If your query isn't addressed here, feel free to contact us directly for further assistance.
                                        </p>
                                    </div>

                                    <div className="faq-container px-xl-5" data-aos="fade-up" data-aos-delay="200">

                                        <div className={`faq-item ${activeFaqIndex === 0 ? 'faq-active' : ''}`} onClick={() => toggleFaq(0)}>
                                            <i className="faq-icon bi bi-question-circle"></i>
                                            <h3>What are the admission criteria for your school?</h3>
                                            <div className="faq-content">
                                                <p>Our school follows the CBSE curriculum and admissions are open for students from Kindergarten to className X. Admissions are granted based on the availability of seats, previous academic records, and a written assessment (for higher classNamees). For nursery and kindergarten, interviews with the child and parents are conducted.</p>
                                            </div>
                                            <i className="faq-toggle bi bi-chevron-right"></i>
                                        </div>

                                        <div className={`faq-item ${activeFaqIndex === 1 ? 'faq-active' : ''}`} onClick={() => toggleFaq(1)}>
                                            <i className="faq-icon bi bi-question-circle"></i>
                                            <h3>What facilities are provided by the school?</h3>
                                            <div className="faq-content">
                                                <p>We are equipped with modern infrastructure including smart classNamerooms, science and computer labs, a library, and sports facilities. The school also has a medical room, transportation services, and well-trained faculty to ensure the overall development of students.</p>
                                            </div>
                                            <i className="faq-toggle bi bi-chevron-right"></i>
                                        </div>

                                        <div className={`faq-item ${activeFaqIndex === 2 ? 'faq-active' : ''}`} onClick={() => toggleFaq(2)}>
                                            <i className="faq-icon bi bi-question-circle"></i>
                                            <h3>How does the school support extracurricular activities?</h3>
                                            <div className="faq-content">
                                                <p>Our school offers a wide range of extracurricular activities including sports, music, dance, drama, art and craft, and various clubs such as Science Club, Eco Club, and Literary Club. These activities help nurture students' talents and promote holistic development.</p>
                                            </div>
                                            <i className="faq-toggle bi bi-chevron-right"></i>
                                        </div>

                                    </div>

                                </div>

                                <div className="col-lg-5 order-1 order-lg-2">
                                    <img src={FAQ} className="img-fluid" alt="" data-aos="zoom-in" data-aos-delay="100" />
                                </div>
                            </div>

                        </div>

                    </section>


                </div>
            </div>

            <div>
                <footer id="footer" className="footer dark-background">

                    <div className="container footer-top">
                        <div className="row gy-4">
                            <div className="col-lg-4 col-md-6 footer-about">
                                <a href="index.html" className="logo d-flex align-items-center">
                                    <span className="sitename" style={{ color: "#f6d731" }}>{SchoolName}</span>
                                    
                                </a>
                                <p>is run and managed by <br></br>Wake Up Educational and Welfare Trust</p>
                                <div className="footer-contact pt-3">
                                    <p>Contact Us : </p>
                                    <p><h5 style={{ color: "#f6d731" }}>Branch-1</h5>Bagan Shahi, Rd. 07, Azad Nagar Mango, </p>
                                    <p>Jamshedpur, Jharkhand</p>
                                    <p className="mt-3"><strong>Phone:</strong> <span>+91 7667615188, +91 9771210427</span></p>

                                    <p><h5 style={{ color: "#f6d731" }}>Branch-2</h5>Road No.19(w),Nr.Azad Marriage Hall ,Old Purulia Road,  Mango, </p>
                                    <p>Jamshedpur, Jharkhand</p>
                                    <p className="mt-3"><strong>Phone:</strong> <span>+91 9905962622, +91 9771210427</span></p>

                                    <p><h5 style={{ color: "#f6d731" }}>Branch-3</h5>Tamulia Main Road,Near Mallick Garden,Opposite Zia Garden  </p>
                                    <p>Kopali, Jharkhand</p>
                                    <p className="mt-3"><strong>Phone:</strong> <span>+91 9279326955</span></p>

                                    {/* <p className="mt-3"><strong>Phone:</strong> <span>+91 6577967565</span></p> */}
                                    <p><strong>Email:</strong> <span>themorningbells2018@gmail.com</span></p>
                                </div>
                                <div className="social-links d-flex mt-4">
                                    {/* <a href=""><i className="bi bi-twitter-x"></i></a> */}
                                    {/* <a href="https://www.facebook.com/morningbellsschooljsr"><i className="bi bi-facebook"></i></a> */}
                                    {/* <a href=""><i className="bi bi-instagram"></i></a> */}
                                    {/* <a href=""><i className="bi bi-linkedin"></i></a> */}
                                </div>
                                {/* Contact number: 9771210427
                                 Facebook id @morningbellsschooljsr
                                 Call number:9771210427
                                 WhatsApp:9771210427 */}
                            </div>

                            <div className="col-lg-2 col-md-3 footer-links">
                                <h4 style={{ color: 'white' }}>Useful Links</h4>
                                <ul>
                                    <li><a href="#">Home</a></li>
                                    <li><a href="#">About us</a></li>

                                </ul>
                            </div>

                            <div className="col-lg-2 col-md-3 footer-links">
                                <h4 style={{ color: 'white' }}>Terms</h4>
                                <ul>
                                    <li><a href="#">Services</a></li>
                                    <li><a href="#">Terms of service</a></li>
                                    <li><a href="#">Privacy policy</a></li>
                                </ul>
                                <img
                                    src={playstore}  // Replace this with your image URL
                                    alt="Our App"
                                    style={{
                                        width: '100%',  // Adjust the size of the image as needed
                                        cursor: 'pointer',  // Makes the image look clickable
                                        borderRadius: '5px',
                                        left: '0px'
                                    }}
                                />
                            </div>

                            <div className="col-lg-4 col-md-12 footer-newsletter ">
                                <h4 style={{ color: 'white' }}>Our Location</h4>
                                {/* Google Map Embed */}
                                <a
                                    href="https://www.google.com/maps?q=The+Morning+Bells+International+School,+Mango+Jamshedpur"

                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {/* <button style={{ padding: '10px', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '5px' }}>
                                        View on OpenStreetMap
                                    </button> */}
                                    <img
                                        src={location}  // Replace this with your image URL
                                        alt="View on OpenStreetMap"
                                        style={{
                                            width: '100%',  // Adjust the size of the image as needed
                                            cursor: 'pointer',  // Makes the image look clickable
                                            borderRadius: '5px',
                                        }}
                                    />
                                </a>

                            </div>

                        </div>
                    </div>

                    <div className="container copyright text-center mt-4">
                        <p>© <span>Copyright</span> <strong className="px-1 sitename">TheVectorX</strong> <span>All Rights Reserved</span></p>
                        <div className="credits">
                            Designed by <a href="">TheVectorX</a>
                        </div>
                    </div>

                </footer>

                <a href="#" id="scroll-top" className="scroll-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>


                {/* <div id="preloader"></div> */}


                <script src="./assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
                <script src="./assets/vendor/php-email-form/validate.js"></script>
                <script src="./assets/vendor/aos/aos.js"></script>
                <script src="./assets/vendor/glightbox/js/glightbox.min.js"></script>
                <script src="./assets/vendor/purecounter/purecounter_vanilla.js"></script>
                <script src="./assets/vendor/swiper/swiper-bundle.min.js"></script>


                <script src="./assets/js/main.js"></script>

            </div>
            <ToastContainer /> {/* Toast container for notifications */}
        </>
    )
}

export default Home

